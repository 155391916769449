import { has } from 'lodash';

export interface Error {
  body: {
    title?: string;
    errors?: {
      title?: string;
    }[];
  };
}

export const isError = (error: unknown): error is Error => has(error, 'body');
